import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import { updateMeta } from "../../../store/actions/metaAction";

const CarServicesCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const history = useHistory();

	const [image, setImage] = useState(userPlaceholder);

	const [formData, setFormData] = useState({});

	const [advantages, setAdvantages] = useState([]);
	const [singleAdvantage, setSingleAdvantage] = useState({});

	const [services, setServices] = useState([]);
	const [singleServices, setSingleServices] = useState({});

	//////////////////////////the start of input for the single  service

	const handleMultiInputChange = e => {
		setFormData({
			...formData,
			[e.target.name]: {
				...formData?.[e.target.name],
				[e.target.lang]: e.target.value,
			},
		});
		console.log(formData, "this is form data");
	};

	const handleServiceNameInputChange = e => {
		setSingleServices({
			...singleServices,
			[e.target.name]: {
				...singleServices?.[e.target.name],
				[e.target.lang]: e.target.value,
			},
		});
		console.log(singleServices, "this is singleServices");
	};

	const handleServiceNumberInputChange = e => {
		setSingleServices({ ...singleServices, [e.target.name]: e.target.value });

		console.log(
			singleServices,
			"this is handleServiceNumberInputChange from service name"
		);
	};

	const handleAddServiceButton = e => {
		setServices([
			...services,
			{ ...singleServices, service_advantages: advantages, id: Math.random() },
		]);
		setAdvantages([]);
		document.querySelectorAll("input").forEach(input => {
			input.value = "";
		});
		document.querySelectorAll("textarea").forEach(textarea => {
			textarea.value = "";
		});
		toast.success("تم اضافه خدمه");

		console.log(
			services,
			"this is services handleAddServiceButton from service name"
		);
		console.log(advantages, "this is the advantages ");
	};

	const handleDeleteServiceButtonClick = (e, id) => {
		setServices(services.filter(service => service.id !== id));

		setAdvantages([]);
	};

	//////////////////////////the end of input for the service

	//////////////////////////the start of input for the advantage of the service

	const handleAdvantageInputChange = e => {
		setSingleAdvantage({
			...singleAdvantage,
			id: Math.random(),
			[e.target.name]: {
				...singleAdvantage?.[e?.target?.name],
				[e.target.lang]: e.target.value,
			},
		});
		console.log(singleAdvantage, "this is handleAdvantageInputChange ");
	};

	const handleDeleteAdvantageButtonClick = (e, id) => {
		setAdvantages(advantages.filter(advantage => advantage.id !== id));
	};
	const handleAddAdvantageButton = e => {
		const advantageExist = advantages.find(
			advantage =>
				advantage?.advantage?.ar === singleAdvantage?.advantage?.ar ||
				advantage?.advantage?.en === singleAdvantage?.advantage?.en
		);

		if (advantageExist) return;

		setAdvantages(prevAdvantages => {
			return [...prevAdvantages, { ...singleAdvantage }];
		});
		toast.success(" تم اضافه ميزه للخدمه");

		console.log(advantages, " this is the advantages");
	};

	//////////////////////////the end of input for the advantages

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة خدمه جديده",
				breadcrumb: "خدمه",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handlePicture = e => {
		const fileInput = document.querySelector(`#${e.target.id}`);
		setImage(URL.createObjectURL(fileInput.files[0]));

		const data = new FormData();
		data.append("file", fileInput.files[0]);
		setLoading(true);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setImage(response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (
			!formData?.title.ar ||
			!formData?.title.en ||
			services.length ||
			!image
		) {
			return toast.error("جميع البيانات مطلوبة");
		}

		setLoading(true);

		const data = {
			...formData,
			services,
			image,
		};

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/posts`,
				{
					admin_id: auth.id,
					category_id: 46,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				console.log(formData, "this is the form data after the response");
				history.push("/homePage/carServices/index");
				toast.success("تم اضافة الخدمه بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-8">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="title">العنوان</label>
									<input
										required
										type="text"
										name="title"
										lang="ar"
										onChange={handleMultiInputChange}
										value={formData?.title?.ar}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="title"> العنوان بالانجليزيه </label>
									<input
										required
										type="text"
										name="title"
										lang="en"
										onChange={handleMultiInputChange}
										value={formData?.title?.en}
									/>
								</div>
							</div>
						</div>

						<div className="card mt-5">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="description">اسم الخدمه</label>
									<textarea
										required
										cols="30"
										rows="2"
										name="service_name"
										lang="ar"
										onChange={handleServiceNameInputChange}
										// value={formData?.service_main_description?.ar}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="description">اسم الخدمه بالانجليزيه</label>
									<textarea
										required
										cols="30"
										rows="2"
										name="service_name"
										lang="en"
										onChange={handleServiceNameInputChange}
										// value={formData?.service_main_description?.ar}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description"> سعر الخدمه</label>
									<input
										required
										type="number"
										name="service_price"
										lang="en"
										onChange={handleServiceNumberInputChange}
										// value={formData?.service_main_description?.en}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description">وقت الخدمه</label>
									<input
										required
										type="number"
										name="service_time"
										lang="ar"
										onChange={handleServiceNumberInputChange}
										// value={formData?.service_sub_description?.ar}
									/>
								</div>
								<div className="form-item mt-3">
									<div
										style={{ cursor: "pointer" }}
										onClick={handleAddServiceButton}
										className="bt bt-success btn-block"
									>
										اضافه خدمه
									</div>
								</div>

								<div className="form-item">
									<label htmlFor="description"> مميزات الخدمه </label>
									<input
										name="advantage"
										lang="ar"
										onChange={handleAdvantageInputChange}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="description">مميزات الخدمه بالانجليزيه</label>
									<input
										name="advantage"
										lang="en"
										onChange={handleAdvantageInputChange}
									/>
								</div>
								<div className="form-item mt-3">
									<div
										style={{ cursor: "pointer" }}
										onClick={handleAddAdvantageButton}
										className="bt bt-success btn-block"
									>
										اضافه ميزه
									</div>
								</div>

								{services?.map(service => {
									console.log("service is ", service);
									return (
										<>
											<h2>
												<span className="bg-dark badge text-light">
													{" "}
													{"اسم الخدمه"}
												</span>
											</h2>

											<div style={{ display: "flex", alignItems: "center" }}>
												<h1 style={{ marginLeft: "10px", fontSize: "20px" }}>
													{service?.service_name?.ar}
												</h1>
												<div
													style={{ cursor: "pointer" }}
													onClick={e =>
														handleDeleteServiceButtonClick(e, service.id)
													}
												>
													&#10006;
												</div>
											</div>

											<h4 className="badge bg-secondary text-light">
												{" مزايا الخدمه"}
											</h4>

											{service?.service_advantages?.map(advantage => {
												return (
													<>
														<div
															style={{ display: "flex", alignItems: "center" }}
														>
															<h1
																style={{ marginLeft: "10px", fontSize: "20px" }}
															>
																{advantage?.advantage?.ar}
															</h1>
															<div
																style={{ cursor: "pointer" }}
																onClick={e =>
																	handleDeleteAdvantageButtonClick(
																		e,
																		advantage.id
																	)
																}
															>
																&#10006;
															</div>
														</div>
													</>
												);
											})}
										</>
									);
								})}
								{/* <h4 className="bg-info text-dark">{"اسماء مزايا الخدمات"}</h4>

								{advantages?.map(advantage => {
									return (
										<>
											<div style={{ display: "flex", alignItems: "center" }}>
												<h1 style={{ marginLeft: "10px", fontSize: "20px" }}>
													{advantage?.advantage?.ar}
												</h1>
												<div
													style={{ cursor: "pointer" }}
													onClick={e =>
														handleDeleteAdvantageButtonClick(e, advantage.id)
													}
												>
													&#10006;
												</div>
											</div>
										</>
									);
								})} */}

								{/* ///////////////////////////////////////////////////////////////// */}
								{/* ///////////////////////////////////////////////////////////////// */}
								{/* ///////////////////////////////////////////////////////////////// */}
							</div>
						</div>
					</div>

					<div className="col-md-4">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input
										type="file"
										onChange={e => handlePicture(e)}
										id="image"
									/>
									<img
										src={`${process.env.REACT_APP_MAIN_URL}${image}`}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default CarServicesCreate;
