import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import { updateMeta } from "../../../store/actions/metaAction";

const OurServicesCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const history = useHistory();

	const [mainImage, setMainImage] = useState(userPlaceholder);
	const [subImage, setSubImage] = useState(userPlaceholder);

	const [formData, setFormData] = useState({});

	const [advantages, setAdvantages] = useState([]);
	const [singleAdvantage, setSingleAdvantage] = useState({});

	const handleMultiInputChange = e => {
		setFormData({
			...formData,
			[e.target.name]: {
				...formData?.[e.target.name],
				[e.target.lang]: e.target.value,
			},
		});
	};
	const handlePriceInputChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleAdvantageInputChange = e => {
		setSingleAdvantage({
			...singleAdvantage,
			id: Math.random(),
			[e.target.name]: {
				...singleAdvantage?.[e?.target?.name],
				[e.target.lang]: e.target.value,
			},
		});
	};

	const handleDeleteButtonClick = (e, elementId) => {
		setAdvantages(advantages.filter(item => item.id !== elementId));
	};
	const handleAddAdvantageButton = e => {
		const advantageExist = advantages.find(
			advantage =>
				advantage?.advantage?.ar === singleAdvantage?.advantage?.ar ||
				advantage?.advantage?.en === singleAdvantage?.advantage?.en
		);

		if (advantageExist) return;

		setAdvantages(prevAdvantages => {
			return [...prevAdvantages, { ...singleAdvantage }];
		});
	};

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة خدمه جديده",
				breadcrumb: "خدمه",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handlePicture = (e, setter) => {
		const fileInput = document.querySelector(`#${e.target.id}`);
		setter(URL.createObjectURL(fileInput.files[0]));
	};

	const handleSubmit = e => {
		e.preventDefault();
		console.log(formData);

		if (
			!formData?.service_main_title.ar ||
			!formData?.service_main_title.en ||
			!formData?.service_main_description.ar ||
			!formData?.service_main_description.en ||
			!formData?.service_sub_description.ar ||
			!formData?.service_sub_description.en ||
			!formData?.service_price
		) {
			return toast.error("جميع البيانات مطلوبة");
		}

		const picture = document.querySelector("#mainImage");
		if (picture.files[0]) {
			setLoading(true);
			const data = new FormData();
			data.append("file", picture.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					const picture = document.querySelector("#subImage");
					if (picture.files[0]) {
						setLoading(true);
						const data = new FormData();
						data.append("file", picture.files[0]);
						axios
							.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
								headers: {
									Authorization: `Bearer ${cookie.get("token")}`,
									Accept: "application/json",
									"Content-Type": "multipart/form-data",
								},
							})
							.then(secondResponse => {
								const data = {
									...formData,
									advantages,
									service_main_image: response.data.data.name,
									service_sub_image: secondResponse.data.data.name,
								};

								axios
									.post(
										`${process.env.REACT_APP_API_URL}/posts`,
										{
											admin_id: auth.id,
											category_id: 43,
											data: data,
										},
										{
											headers: {
												Authorization: `Bearer ${cookie.get("token")}`,
												Accept: "application/json",
											},
										}
									)
									.then(function (response) {
										history.push("/homePage/ourServices/index");
										toast.success("تم اضافة الخدمه بنجاح!");
										setLoading(false);
									})
									.catch(function (error) {
										toast.error("خطأ، برجاء المحاولة مره اخرى!");
										setLoading(false);
									});
							})
							.catch(err => {
								toast.error("خطأ، برجاء المحاولة مره اخرى!");
								setLoading(false);
							});
					}
				})
				.catch(error => {
					console.log(error);
					toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
					setLoading(false);
				});
		} else {
			toast.error("برجاء اختيار الصورة أولا!");
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-8">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="title">العنوان</label>
									<input
										type="text"
										name="service_main_title"
										lang="ar"
										onChange={handleMultiInputChange}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="title"> العنوان بالانجليزيه </label>
									<input
										type="text"
										name="service_main_title"
										lang="en"
										onChange={handleMultiInputChange}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description">الوصف</label>
									<textarea
										cols="30"
										rows="2"
										name="service_main_description"
										lang="ar"
										onChange={handleMultiInputChange}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description"> الوصف بالانجليزيه</label>
									<textarea
										cols="30"
										rows="2"
										name="service_main_description"
										lang="en"
										onChange={handleMultiInputChange}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description">الوصف الفرعي</label>
									<textarea
										cols="30"
										rows="2"
										name="service_sub_description"
										lang="ar"
										onChange={handleMultiInputChange}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description"> الوصف الفرعي بالانجليزيه</label>
									<textarea
										cols="30"
										rows="2"
										name="service_sub_description"
										lang="en"
										onChange={handleMultiInputChange}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description"> سعر الخدمه </label>
									<input
										type="number"
										name="service_price"
										onChange={handlePriceInputChange}
									/>
								</div>

								{/* ///////////////////////////////////////////////////////////////// */}
								{/* ///////////////////////////////////////////////////////////////// */}
								{/* ///////////////////////////////////////////////////////////////// */}

								<div className="form-item">
									<label htmlFor="description"> مميزات الخدمه </label>
									<input
										name="advantage"
										lang="ar"
										onChange={handleAdvantageInputChange}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="description">مميزات الخدمه بالانجليزيه</label>
									<input
										name="advantage"
										lang="en"
										onChange={handleAdvantageInputChange}
									/>
								</div>
								<div className="form-item mt-3">
									<div
										style={{ cursor: "pointer" }}
										onClick={handleAddAdvantageButton}
										className="bt bt-success btn-block"
									>
										اضافه ميزه
									</div>
								</div>

								{advantages.map(element => {
									return (
										<div style={{ display: "flex", alignItems: "center" }}>
											<h1 style={{ marginLeft: "10px", fontSize: "20px" }}>
												{element.advantage.ar}
											</h1>
											<div
												style={{ cursor: "pointer" }}
												onClick={e => handleDeleteButtonClick(e, element.id)}
											>
												&#10006;
											</div>
										</div>
									);
								})}

								{/* ///////////////////////////////////////////////////////////////// */}
								{/* ///////////////////////////////////////////////////////////////// */}
								{/* ///////////////////////////////////////////////////////////////// */}
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input
										type="file"
										onChange={e => handlePicture(e, setMainImage)}
										id="mainImage"
									/>
									<img src={mainImage} alt="avatar" className="imagePreview" />
								</div>
							</div>
							<div className="card-body">
								<div className="form-item">
									<input
										type="file"
										onChange={e => handlePicture(e, setSubImage)}
										id="subImage"
									/>
									<img src={subImage} alt="avatar" className="imagePreview" />
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default OurServicesCreate;
