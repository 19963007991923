import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import cookie from "js-cookie";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import { updateMeta } from "../../../store/actions/metaAction";

const CarServicesShow = ({ match }) => {
	const {
		params: { carServiceID },
	} = match;

	const auth = useSelector(state => state.auth.user);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const history = useHistory();

	const [formData, setFormData] = useState({});

	const [image, setImage] = useState(userPlaceholder);

	const [services, setServices] = useState([]);
	const [singleServices, setSingleServices] = useState({});

	const [advantages, setAdvantages] = useState([]);
	const [singleAdvantage, setSingleAdvantage] = useState({});

	// const [addNewAdvantage, setAddNewAdvantage] = useState(false);
	// const [newAdvantage, setNewAdvantage] = useState({});

	//////////////////////// add new advantage for existing service

	// const handleAddAdvantageToExistingsService = ()=>{

	// }

	//////////////////////////the start of input for the single  service

	const handleMultiInputChange = e => {
		setFormData({
			...formData,
			[e.target.name]: {
				...formData?.[e.target.name],
				[e.target.lang]: e.target.value,
			},
		});
	};

	const handleServiceNameInputChange = e => {
		setSingleServices({
			...singleServices,
			[e.target.name]: {
				...singleServices?.[e.target.name],
				[e.target.lang]: e.target.value,
			},
		});
	};

	const handleServiceNumberInputChange = e => {
		setSingleServices({ ...singleServices, [e.target.name]: e.target.value });
	};

	const handleAddServiceButton = e => {
		setServices([
			...services,
			{ ...singleServices, service_advantages: advantages, id: Math.random() },
		]);
		setAdvantages([]);
		toast.success("تم اضافة الخدمه بنجاح!");
	};

	const handleDeleteServiceButtonClick = (e, id) => {
		setServices(services.filter(service => service.id !== id));

		setAdvantages([]);
	};

	//////////////////////////the end of input for the service

	//////////////////////////the start of input for the advantage of the service

	const handleAdvantageInputChange = e => {
		setSingleAdvantage({
			...singleAdvantage,
			id: Math.random(),
			[e.target.name]: {
				...singleAdvantage?.[e?.target?.name],
				[e.target.lang]: e.target.value,
			},
		});
	};

	const handleDeleteAdvantageButtonClick = (e, serviceId, advantageId) => {
		const newServices = services.map(service => {
			return service.id === serviceId
				? {
						...service,
						service_advantages: service.service_advantages.filter(
							advantage => advantage.id !== advantageId
						),
				  }
				: { ...service };
		});
		setServices(newServices);
	};

	const handleAddAdvantageButton = e => {
		const advantageExist = advantages.find(
			advantage =>
				advantage?.advantage?.ar === singleAdvantage?.advantage?.ar ||
				advantage?.advantage?.en === singleAdvantage?.advantage?.en
		);

		if (advantageExist) return;

		setAdvantages(prevAdvantages => {
			return [...prevAdvantages, { ...singleAdvantage }];
		});
		toast.success("تم اضافة ميزه للخدمه!");
	};

	//////////////////////////the end of input for the advantages

	////////////////////////////////add advantage to existing service

	const addAdvantagetoExistingService = (serviceId, newAdvantage) => {
		const newService = services.map(service => {
			return service.id === serviceId
				? {
						...service,
						service_advantages: service.service_advantages.push({
							newAdvantage,
						}),
				  }
				: { ...services };
		});
		setServices(newService);
	};

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل الخدمه",
				breadcrumb: "الخدمه",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/${carServiceID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setFormData(response?.data?.data?.data);
				setImage(response?.data?.data?.data?.image);
				setServices(response?.data?.data?.data?.services);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handlePicture = e => {
		setLoading(true);
		const fileInput = document.querySelector(`#${e.target.id}`);
		setImage(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setImage(response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	// const handleService = ()=>{
	// 	return (

	// 	)

	// }

	const handleSubmit = e => {
		e.preventDefault();
		// if (
		// 	!formData?.title.ar ||
		// 	!formData?.title.en ||
		// 	!formData?.service_main_description.ar ||
		// 	!formData?.service_main_description.en ||
		// 	!formData?.service_sub_description.ar ||
		// 	!formData?.service_sub_description.en ||
		// 	!formData?.service_price
		// ) {
		// 	return toast.error("جميع البيانات مطلوبة");
		// }
		setLoading(true);

		const data = {
			...formData,
			services,
			image: image,
		};

		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/${carServiceID}`,
				{
					admin_id: auth.id,
					category_id: 46,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)

			.then(function (response) {
				history.push("/homePage/carServices/index");
				toast.success("تم اضافة الخدمه بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	const handleServicesData = () => {
		return (
			<>
				{services?.map(service => {
					return (
						<table className="table table-bordered table-hover mt-5 ">
							<thead className="bg-light">
								<th scope="col">اسم الخدمه</th>
								<th scope="col">مميزات الخدمه</th>
							</thead>
							<div className="d-flex ">
								<tr className="d-flex badge bg-light">
									<h5>
										<span>{service?.service_name?.ar}</span>
									</h5>

									<div
										style={{
											cursor: "pointer",
											paddingRight: "5px",
											marginTop: "8px",
										}}
										onClick={e => handleDeleteServiceButtonClick(e, service.id)}
									>
										&#10006;
									</div>
								</tr>
							</div>
							{service.service_advantages.map((advantage, index) => {
								return (
									<>
										<tbody>
											<tr className="">
												<th scope="row">{index + 1}</th>
												<td className="d-flex">
													{" "}
													{advantage?.advantage?.ar}
													<div
														style={{ cursor: "pointer", paddingRight: "5px" }}
														onClick={e =>
															handleDeleteAdvantageButtonClick(
																e,
																service.id,
																advantage.id
															)
														}
													>
														&#10006;
													</div>
												</td>
											</tr>
										</tbody>
									</>
								);
							})}
						</table>
					);
				})}
			</>
		);
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-8">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="title">العنوان</label>
									<input
										type="text"
										name="title"
										lang="ar"
										onChange={handleMultiInputChange}
										value={formData?.title?.ar}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="title"> العنوان بالانجليزيه </label>
									<input
										type="text"
										name="title"
										lang="en"
										onChange={handleMultiInputChange}
										value={formData?.title?.en}
									/>
								</div>
							</div>
						</div>

						<div className="card mt-5">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="description">اسم الخدمه</label>
									<textarea
										cols="30"
										rows="2"
										name="service_name"
										lang="ar"
										onChange={handleServiceNameInputChange}
										// value={formData?.service_main_description?.ar}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="description">اسم الخدمه بالانجليزيه</label>
									<textarea
										cols="30"
										rows="2"
										name="service_name"
										lang="en"
										onChange={handleServiceNameInputChange}
										// value={formData?.service_main_description?.ar}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description"> سعر الخدمه</label>
									<input
										type="number"
										name="service_price"
										lang="en"
										onChange={handleServiceNumberInputChange}
										// value={formData?.service_main_description?.en}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description">وقت الخدمه</label>
									<input
										type="number"
										name="service_time"
										lang="ar"
										onChange={handleServiceNumberInputChange}
										// value={formData?.service_sub_description?.ar}
									/>
								</div>
								<div className="form-item mt-3">
									<div
										style={{ cursor: "pointer" }}
										onClick={handleAddServiceButton}
										className="bt bt-success btn-block"
									>
										اضافه خدمه
									</div>
								</div>

								<div className="form-item">
									<label htmlFor="description"> مميزات الخدمه </label>
									<input
										name="advantage"
										lang="ar"
										onChange={handleAdvantageInputChange}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="description">مميزات الخدمه بالانجليزيه</label>
									<input
										name="advantage"
										lang="en"
										onChange={handleAdvantageInputChange}
									/>
								</div>
								<div className="form-item mt-3">
									<div
										style={{ cursor: "pointer" }}
										onClick={handleAddAdvantageButton}
										className="bt bt-success btn-block"
									>
										اضافه ميزه
									</div>
								</div>

								{handleServicesData()}

								{/* ///////////////////////////////////////////////////////////////// */}
								{/* ///////////////////////////////////////////////////////////////// */}
								{/* ///////////////////////////////////////////////////////////////// */}
							</div>
						</div>
					</div>

					<div className="col-md-4">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input
										type="file"
										onChange={e => handlePicture(e)}
										id="image"
									/>
									<img
										src={`${process.env.REACT_APP_MAIN_URL}${image}`}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default CarServicesShow;
