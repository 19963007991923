import { BrowserRouter as Router, Switch } from "react-router-dom";
import GeustRoute from "./helpers/authentication/geustRoute";
import Layout from "./components/layout/Layout";
import AuthRoute from "./helpers/authentication/authRoute";
import Login from "./views/authentication/Login";
import Home from "./views/Home";
import ModeratorsIndex from "./views/moderators/ModeratorsIndex";
import ModeratorsCreate from "./views/moderators/ModeratorsCreate";
import ModeratorShow from "./views/moderators/ModeratorShow";
import BlogIndex from "./views/blog/BlogIndex";
import BlogCreate from "./views/blog/BlogCreate";
import BlogShow from "./views/blog/BlogShow";
import CategoriesIndex from "./views/blog/categories/CategoriesIndex";
import CategoriesCreate from "./views/blog/categories/CategoriesCreate";
import CategoriesShow from "./views/blog/categories/CategoriesShow";
import ServicesIndex from "./views/services/ServicesIndex";
import ServicesCreate from "./views/services/ServicesCreate";
import ServiceShow from "./views/services/ServiceShow";
import ServicesApplications from "./views/services/ServicesApplications";
import ServiceShowApplication from "./views/services/ServiceShowApplication";

import ContactIndex from "./views/contact/ContactIndex";
import ContactShow from "./views/contact/ContactShow";

import LinksIndex from "./views/footerLinks/LinksIndex";
import LinksCreate from "./views/footerLinks/LinksCreate";
import LinksShow from "./views/footerLinks/LinksShow";

import NewsletterIndex from "./views/newsletter/NewsletterIndex";
import NewsletterCreate from "./views/newsletter/NewsletterCreate";
// import About from "./views/About";
import Settings from "./views/Settings";

// import AutomotivePostsCreate from "./views/automotivePage/automotivePosts/AutomotivePostsCreate";
// import AutomotivePostsIndex from "./views/automotivePage/automotivePosts/AutomotivePostsIndex";
// import AutomotivePostsShow from "./views/automotivePage/automotivePosts/AutomotivePostsShow";

// import AutomotiveVideoCreate from "./views/automotivePage/automotiveVideo/AutomotiveVideoCreate";
// import AutomotiveVideoIndex from "./views/automotivePage/automotiveVideo/AutomotiveVideoIndex";
// import AutomotiveVideoShow from "./views/automotivePage/automotiveVideo/AutomotiveVideoShow";
// import AutomotiveSectionsCreate from "./views/automotivePage/automotiveSections/AutomotiveSectionsCreate";
// import AutomotiveSectionsIndex from "./views/automotivePage/automotiveSections/AutomotiveSectionsIndex";
// import AutomotiveSectionsShow from "./views/automotivePage/automotiveSections/AutomotiveSectionsShow";

// import AutomotiveHeadersCreate from "./views/automotivePage/automotiveHeaders/AutomotiveHeadersCreate";
// import AutomotiveHeadersShow from "./views/automotivePage/automotiveHeaders/AutomotiveHeadersShow";
// import AutomotiveHeadersIndex from "./views/automotivePage/automotiveHeaders/AutomotiveHeadersIndex";

// import AutomotiveSplitersCreate from "./views/automotivePage/automotiveSpliters/AutomotiveSplitersCreate";
// import AutomotiveSplitersIndex from "./views/automotivePage/automotiveSpliters/AutomotiveSplitersIndex";
// import AutomotiveSplitersShow from "./views/automotivePage/automotiveSpliters/AutomotiveSplitersShow";

import AboutUsShow from "./views/homePage/aboutUs/AboutUsShow";

import SliderCreate from "./views/homePage/slider/SliderCreate";
import SliderShow from "./views/homePage/slider/SliderShow";
import SliderIndex from "./views/homePage/slider/SliderIndex";
import AdvantagesShow from "./views/homePage/advatages/AdvantagesShow";
import AdvantagesIndex from "./views/homePage/advatages/AdvantagesIndex";
import AdvantagesCreate from "./views/homePage/advatages/AdvantagesCreate";
import OurServicesCreate from "./views/homePage/ourServices/OurServicesCreate";
import OurServicesIndex from "./views/homePage/ourServices/OurServicesIndex";
import OurServicesShow from "./views/homePage/ourServices/OurServicesShow";
import FormCreate from "./views/homePage/form/FormCreate";
import FormIndex from "./views/homePage/form/FormIndex";
import FormShow from "./views/homePage/form/FormShow";
import TestimonialCreate from "./views/homePage/testimonial/TestimonialCreate";
import TestimonialIndex from "./views/homePage/testimonial/TestimonialIndex";
import TestimonialShow from "./views/homePage/testimonial/TestimonialShow";
import CarServicesCreate from "./views/homePage/carServices/CarServicesCreate";
import CarServicesIndex from "./views/homePage/carServices/CarServicesIndex";
import CarServicesShow from "./views/homePage/carServices/CarServicesShow";

function App() {
	return (
		<Router basename="/dashboard">
			<Switch>
				<GeustRoute path="/login" component={Login} />
				<Layout>
					<AuthRoute path="/" exact component={Home} />
					<AuthRoute path="/dashboard" component={Home} />
					<AuthRoute path="/moderators/index" component={ModeratorsIndex} />
					<AuthRoute path="/moderators/create" component={ModeratorsCreate} />
					<AuthRoute
						path="/moderators/show/:moderatorID"
						component={ModeratorShow}
					/>
					<AuthRoute path="/blog/index" component={BlogIndex} />
					<AuthRoute path="/blog/create" component={BlogCreate} />
					<AuthRoute path="/blog/show/:blogID" component={BlogShow} />
					<AuthRoute path="/categories/index" component={CategoriesIndex} />
					<AuthRoute path="/categories/create" component={CategoriesCreate} />
					<AuthRoute
						path="/categories/show/:categoryID"
						component={CategoriesShow}
					/>
					<AuthRoute path="/services/index" component={ServicesIndex} />
					<AuthRoute path="/services/create" component={ServicesCreate} />
					<AuthRoute path="/services/show/:serviceID" component={ServiceShow} />
					<AuthRoute
						path="/services/applications"
						component={ServicesApplications}
					/>
					<AuthRoute
						path="/services/application/:applicationID"
						component={ServiceShowApplication}
					/>

					<AuthRoute path="/contact/index" component={ContactIndex} />
					<AuthRoute path="/contact/show/:contactID" component={ContactShow} />
					<AuthRoute path="/newsletter/index" component={NewsletterIndex} />
					<AuthRoute path="/newsletter/send" component={NewsletterCreate} />
					{/* <AuthRoute path="/about/show" component={About} /> */}
					<AuthRoute path="/settings" component={Settings} />

					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* //////////////////////////////////////////////////////////////////////// */}

					{/* <AuthRoute
						path="/automotivePage/automotivePosts/create"
						component={AutomotivePostsCreate}
					/>
					<AuthRoute
						path="/automotivePage/automotivePosts/index"
						component={AutomotivePostsIndex}
					/>
					<AuthRoute
						path="/automotivePage/automotivePosts/show/:automotivePostId"
						component={AutomotivePostsShow}
					/> */}
					{/* ///////////////////////////////////////////// */}
					{/* <AuthRoute
						path="/automotivePage/automotiveVideo/create"
						component={AutomotiveVideoCreate}
					/>
					<AuthRoute
						path="/automotivePage/automotiveVideo/index"
						component={AutomotiveVideoIndex}
					/>
					<AuthRoute
						path="/automotivePage/automotiveVideo/show/:automotiveVideoId"
						component={AutomotiveVideoShow}
					/> */}
					{/* ///////////////////////////////////////////// */}
					{/* <AuthRoute
						path="/automotivePage/automotiveSections/create"
						component={AutomotiveSectionsCreate}
					/>
					<AuthRoute
						path="/automotivePage/automotiveSections/index"
						component={AutomotiveSectionsIndex}
					/>
					<AuthRoute
						path="/automotivePage/automotiveSections/show/:automotiveSectionId"
						component={AutomotiveSectionsShow}
					/> */}

					{/* ///////////////////////////////////////////////////////////////////////////////////////// */}

					{/* /////////////////////////////////////////////////////// */}

					{/* <AuthRoute
						path="/automotivePage/automotiveHeaders/create"
						component={AutomotiveHeadersCreate}
					/>
					<AuthRoute
						path="/automotivePage/automotiveHeaders/index"
						component={AutomotiveHeadersIndex}
					/>
					<AuthRoute
						path="/automotivePage/automotiveHeaders/show/:automotiveHeaderId"
						component={AutomotiveHeadersShow}
					/> */}
					{/* /////////////////////////////////////////////////////// */}

					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* //////////////////////////////////////////////////////////////////////// */}

					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* <AuthRoute
						path="/automotivePage/automotiveSpliters/create"
						component={AutomotiveSplitersCreate}
					/>
					<AuthRoute
						path="/automotivePage/automotiveSpliters/index"
						component={AutomotiveSplitersIndex}
					/>
					<AuthRoute
						path="/automotivePage/automotiveSpliters/show/:automotiveSplitersId"
						component={AutomotiveSplitersShow}
					/> */}
					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* //////////////////////////////////////////////////////////////////////// */}

					<AuthRoute path="/homePage/slider/create" component={SliderCreate} />
					<AuthRoute path="/homePage/slider/index" component={SliderIndex} />
					<AuthRoute
						path="/homePage/slider/show/:slideID"
						component={SliderShow}
					/>
					{/* //////////////////////////////////////// */}

					<AuthRoute path="/homePage/aboutUs/show" component={AboutUsShow} />
					{/* //////////////////////////////////////// */}

					<AuthRoute
						path="/homePage/advantages/create"
						component={AdvantagesCreate}
					/>
					<AuthRoute
						path="/homePage/advantages/index"
						component={AdvantagesIndex}
					/>
					<AuthRoute
						path="/homePage/advantages/show/:advantageID"
						component={AdvantagesShow}
					/>
					{/* //////////////////////////////////////// */}
					{/* //////////////////////////////////////// */}

					<AuthRoute
						path="/homePage/ourServices/create"
						component={OurServicesCreate}
					/>
					<AuthRoute
						path="/homePage/ourServices/index"
						component={OurServicesIndex}
					/>
					<AuthRoute
						path="/homePage/ourServices/show/:ourServiceID"
						component={OurServicesShow}
					/>
					{/* ////////////////////form components/////////////////// */}

					<AuthRoute path="/homePage/form/create" component={FormCreate} />
					<AuthRoute path="/homePage/form/index" component={FormIndex} />
					<AuthRoute
						path="/homePage/form/show/:formDataID"
						component={FormShow}
					/>
					{/* ////////////////////testimonial components/////////////////// */}

					<AuthRoute
						path="/homePage/testimonial/create"
						component={TestimonialCreate}
					/>
					<AuthRoute
						path="/homePage/testimonial/index"
						component={TestimonialIndex}
					/>
					<AuthRoute
						path="/homePage/testimonial/show/:testimonialID"
						component={TestimonialShow}
					/>
					{/* ////////////////////carServices components/////////////////// */}

					<AuthRoute
						path="/homePage/carServices/create"
						component={CarServicesCreate}
					/>
					<AuthRoute
						path="/homePage/carServices/index"
						component={CarServicesIndex}
					/>
					<AuthRoute
						path="/homePage/carServices/show/:carServiceID"
						component={CarServicesShow}
					/>
					{/* ////////////////////footer components/////////////////// */}

					<AuthRoute path="/footerLinks/create" component={LinksCreate} />
					<AuthRoute path="/footerLinks/index" component={LinksIndex} />
					<AuthRoute path="/footerLinks/show/:linkID" component={LinksShow} />
				</Layout>
			</Switch>
		</Router>
	);
}

export default App;
